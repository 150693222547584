import React, { useState, FormEvent, ChangeEvent, KeyboardEvent, useRef, useEffect } from 'react';
import { Button } from '../../components/ui/Button-chat';
import { Textarea } from '../../components/ui/Textarea-chat';
import { ArrowUp, Sparkles } from 'lucide-react';

interface ChatInputProps {
  onSendMessage: (message: string) => void;
  disabled?: boolean;
  showSuggestions?: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({ 
  onSendMessage, 
  disabled,
  showSuggestions = true 
}) => {
  const [message, setMessage] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    // Focus textarea immediately after sending message
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [disabled]); // Focus when disabled state changes

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const trimmed = message.trim();
    if (trimmed && !disabled) {
      onSendMessage(trimmed);
      setMessage(''); // Clear message immediately
      // Focus back on textarea
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    }
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      const trimmed = message.trim();
      if (trimmed && !disabled) {
        onSendMessage(trimmed);
        setMessage('');
        // Focus back on textarea
        if (textareaRef.current) {
          textareaRef.current.focus();
        }
      }
    }
  };

  const handleExampleClick = (prompt: string) => {
    if (!disabled) {
      onSendMessage(prompt); // Directly send the message
      setMessage(''); // Clear the input
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    }
  };

  return (
    <div className="input-container">
      {showSuggestions && (
        <div className="example-prompts">
          <div 
            className="prompt-tile"
            onClick={() => handleExampleClick("Help me choose between Computer Science and Data Science as my major.")}
          >
            <h4>
              <Sparkles className="inline-block w-4 h-4" />
              Career Guidance
            </h4>
            <p>Help me choose between Computer Science and Data Science as my major</p>
          </div>
          <div 
            className="prompt-tile"
            onClick={() => handleExampleClick("What are the key skills I should develop for a career in artificial intelligence?")}
          >
            <h4>
              <Sparkles className="inline-block w-4 h-4" />
              Skill Development
            </h4>
            <p>What are the key skills I should develop for a career in AI?</p>
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit} className="form-container">
        <div className="input-flex">
          <Textarea
            ref={textareaRef}
            value={message}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder="Send a message..."
            className="chat-textarea"
            rows={1}
          />
          <Button
            type="submit"
            variant="icon"
            disabled={!message.trim() || disabled}
            className={`chat-send-button ${message.trim() && !disabled ? 'active' : ''}`}
          >
            <ArrowUp className="h-5 w-5" />
            <span className="sr-only">Send message</span>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ChatInput;
