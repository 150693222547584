import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';
import axiosClient from '../axios-client';
import axios from 'axios';

const GoogleRedirect = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const handleGoogleRedirect = async () => {
      const code = searchParams.get('code');
      
      if (!code) {
        toast.error('Authentication failed', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        navigate('/auth/login');
        return;
      }

      try {
        const result = await axiosClient.post('/auth/google/callback', { code });
        const token = result.data.tokens.access.token;
        
        axios.defaults.headers['Authorization'] = `Bearer ${token}`;

        toast.success('Successfully logged in with Google', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });

        navigate('/dashboard');
      } catch (error: any) {
        toast.error(error.response?.data?.message || 'Authentication failed', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        navigate('/auth/login');
      }
    };

    handleGoogleRedirect();
  }, [searchParams, navigate]);

  return (
    <div className="auth-wrapper">
      <div className="auth-container">
        <h2>Processing Google Login...</h2>
        {/* You could add a loading spinner here */}
      </div>
    </div>
  );
};

export default GoogleRedirect; 