import React from 'react';
import { motion } from 'framer-motion';
import { Sparkles, ThumbsUp, ThumbsDown, Copy, Check } from 'lucide-react';

interface Message {
  id: string;
  content: string;
  role: 'user' | 'assistant';
}

interface ChatMessageProps {
  message: Message;
}

const ChatMessage: React.FC<ChatMessageProps> = ({ message }) => {
  const isAssistant = message.role === 'assistant';
  const [isCopied, setIsCopied] = React.useState(false);
  const [feedback, setFeedback] = React.useState<'like' | 'dislike' | null>(null);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(message.content);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  const handleFeedback = (type: 'like' | 'dislike') => {
    setFeedback(type === feedback ? null : type);
  };
  
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className={`message-wrapper ${isAssistant ? 'assistant' : 'user'}`}
    >
      {isAssistant && (
        <>
          <div className="ai-avatar">
            <Sparkles className="sparkles-icon" />
          </div>
          <div className="message-bubble">
            <div className="message-content">
              {message.content}
            </div>
            <div className="message-actions">
              <button 
                className="action-button"
                onClick={handleCopy}
                title="Copy"
              >
                {isCopied ? <Check /> : <Copy />}
              </button>
              <button 
                className={`action-button ${feedback === 'like' ? 'active' : ''}`}
                onClick={() => handleFeedback('like')}
                title="Like"
              >
                <ThumbsUp />
              </button>
              <button 
                className={`action-button ${feedback === 'dislike' ? 'active' : ''}`}
                onClick={() => handleFeedback('dislike')}
                title="Dislike"
              >
                <ThumbsDown />
              </button>
            </div>
          </div>
        </>
      )}
      {!isAssistant && (
        <div className="message-bubble">
          <div className="message-content">{message.content}</div>
        </div>
      )}
    </motion.div>
  );
};

export default ChatMessage;
