import React, { useState, useEffect, DragEvent } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid"; // Import uuid for generating unique IDs
import "../styles/components/accountInfo.scss";
import axiosClient from "../axios-client";
import { FaGithub, FaLinkedin, FaEnvelope } from "react-icons/fa";
import { FiShare2 } from "react-icons/fi";
import { FaXTwitter } from "react-icons/fa6";
import { MdDragIndicator } from "react-icons/md"; // Import the drag icon
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import Sidebar from "./Sidebar";
import moment from "moment";
import "../styles/global.scss";
import "@radix-ui/themes/styles.css";

import "react-datepicker/dist/react-datepicker.css";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogClose,
  DialogTrigger,
  DialogFooter,
} from "../components/ui/Dialog"; // Corrected path to the Dialog component

import { Input } from "../components/ui/Input"; // Corrected path to the Input component
import { Label } from "../components/ui/Label"; // Corrected path to the Label component
import { Bounce, toast } from "react-toastify";

import { range } from "lodash";

import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "./ui/Select";
import { Button } from "./ui/Button";

interface ExperienceItem {
  title: string;
  company: string;
  location: string;
  duration: string;
  startDate: string;
  endDate: string | null;
  isCurrentPosition: boolean;
  description: Array<{ id: string; text: string }>;
}

const formatPhoneNumber = (value: string) => {
  // Remove all non-numeric characters
  const number = value.replace(/[^\d]/g, '');
  
  // Format number
  if (number.length <= 3) {
    return number;
  } else if (number.length <= 6) {
    return `(${number.slice(0, 3)}) ${number.slice(3)}`;
  } else {
    return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6, 10)}`;
  }
};

const formatUrl = (url: string, type: 'website' | 'linkedin') => {
  if (!url) return '';
  let cleanUrl = url.replace(/^(https?:\/\/)?(www\.)?/, '');
  return type === 'linkedin' 
    ? (cleanUrl.startsWith('linkedin.com/') ? `https://${cleanUrl}` : `https://linkedin.com/${cleanUrl}`)
    : `https://${cleanUrl}`;
};

const AccountInfo: React.FC = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [website, setWebsite] = useState("");
  const [location, setLocation] = useState("");
  const [citizenship, setCitizenship] = useState("");
  const [experienceLevel, setExperienceLevel] = useState("");
  const [education, setEducation] = useState([
    { degree: "", field: "", university: "", graduationDate: "", gpa: "" },
  ]);
  const [experience, setExperience] = useState<ExperienceItem[]>([]);
  const [projects, setProjects] = useState([
    {
      title: "",
      projectName: "",
      duration: "",
      description: [{ id: uuidv4(), text: "" }],
    },
  ]);
  const [skills, setSkills] = useState([""]);
  const [interests, setInterests] = useState([""]);
  const [profileImage, setProfileImage] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [date, setDate] = React.useState<Date | undefined>(new Date());

  const [tempFirstName, setTempFirstName] = useState("");
  const [tempLastName, setTempLastName] = useState("");
  const [tempExperienceLevel, setTempExperienceLevel] = useState("");
  const [tempLocation, setTempLocation] = useState("");
  const [tempLinkedin, setTempLinkedin] = useState("");
  const [tempWebsite, setTempWebsite] = useState("");

  const [tempEducation, setTempEducation] = useState(education);
  const [tempExperience, setTempExperience] = useState(experience);
  const [tempProjects, setTempProjects] = useState(projects);

  const [editingEducationIndex, setEditingEducationIndex] = useState<
    number | null
  >(null);
  const [editingExperienceIndex, setEditingExperienceIndex] = useState<
    number | null
  >(null);
  const [editingProjectIndex, setEditingProjectIndex] = useState<number | null>(
    null
  );

  const [isCurrentPosition, setIsCurrentPosition] = useState<boolean>(false);

  const [username, setUsername] = useState("");

  const navigate = useNavigate();

  const years = range(1990, getYear(new Date()) + 1, 1);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const experienceStartDate = new Date("2024-08-01");
  const experienceEndDate = new Date("2024-10-01");

  const [startDate, setStartDate] = useState(experienceStartDate);
  const [endDate, setEndDate] = useState(experienceEndDate);

  const handleChange = (newStartDate: any, newEndDate: any) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const user = JSON.parse(String(localStorage.getItem("user")));
      if (user) {
        try {
          const response = await axiosClient.get(`users/${user._id}`);
          const data = response.data;
          if (data) {
            // Convert string descriptions to object format with "text" and assign unique ids
            const updatedExperience = data.experience.map((exp: any) => ({
              ...exp,
              description: exp.description.map((desc: any) =>
                typeof desc === "string" ? { id: uuidv4(), text: desc } : desc
              ),
            }));

            const updatedProjects = data.projects.map((proj: any) => ({
              ...proj,
              description: proj.description.map((desc: any) =>
                typeof desc === "string" ? { id: uuidv4(), text: desc } : desc
              ),
            }));

            setFirstName(data.firstName || "");
            setLastName(data.lastName || "");
            setEmail(data.email || "");
            setPhone(data.phone || "");
            setLinkedin(data.linkedin || "");
            setWebsite(data.website || "");
            setLocation(data.location || "");
            setCitizenship(data.citizenship || "");
            setExperienceLevel(data.experienceLevel || "Entry Level");
            setEducation(data.education || []);
            setExperience(updatedExperience || []);
            setProjects(updatedProjects || []);
            setSkills(data.skills || []);
            setInterests(data.interests || []);
            setProfileImage(data.profileImage || "");
            setUsername(data.username || "");

            // Set temporary states
            setTempFirstName(data.firstName || "");
            setTempLastName(data.lastName || "");
            setTempExperienceLevel(data.experienceLevel || "Entry Level");
            setTempLocation(data.location || "");
            setTempLinkedin(data.linkedin || "");
            setTempWebsite(data.website || "");
            setTempEducation([...data.education] || []);
            setTempExperience([...updatedExperience] || []);
            setTempProjects([...updatedProjects] || []);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setError("Failed to fetch user data.");
          setTimeout(() => setError(""), 10000);
        }
      } else {
        navigate("/auth/login");
      }
    };

    fetchUserData();
  }, [navigate]);

  const handleUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    try {
      const user = JSON.parse(String(localStorage.getItem("user")));
      if (user) {
        await axiosClient.patch(`users/${user._id}`, {
          firstName,
          lastName,
          email,
          phone,
          linkedin,
          website,
          location,
          citizenship,
          education,
          experience,
          projects,
          skills,
          interests,
          profileImage,
        });
        setSuccess("Profile updated successfully");
        setTimeout(() => setSuccess(""), 10000);
      }
    } catch (error: any) {
      console.error("Error updating profile:", error);
      setError("Failed to update profile.");
      setTimeout(() => setError(""), 10000);
    }
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const addEducation = () => {
    const newEducation = { degree: "", field: "", university: "", graduationDate: "", gpa: "" };
    setTempEducation([...education, newEducation]);
    setEducation([...education, newEducation]);
    setEditingEducationIndex(education.length); // Immediately set editing index to the new item
  };

  const removeEducation = async (index: number) => {
    try {
      const newEducation = education.filter((_, i) => i !== index);
      const user = JSON.parse(String(localStorage.getItem("user")));
      if (user) {
        await axiosClient.patch(`users/${user._id}`, {
          education: newEducation,
        });
        setEducation(newEducation);
        setTempEducation(newEducation);
        setEditingEducationIndex(null);
        toast("Education removed successfully", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    } catch (error) {
      console.error("Error removing education:", error);
      toast("Failed to remove education", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const addExperience = () => {
    const newExperience = {
      title: "",
      company: "",
      location: "",
      duration: "",
      description: [{ id: uuidv4(), text: "" }],
      startDate: "",
      endDate: null,
      isCurrentPosition: false,
    };
    setTempExperience([...experience, newExperience]);
    setExperience([...experience, newExperience]);
    setEditingExperienceIndex(experience.length); // Immediately set editing index to the new item
  };

  const removeExperience = async (index: number) => {
    try {
      const newExperience = experience.filter((_, i) => i !== index);
      const user = JSON.parse(String(localStorage.getItem("user")));
      if (user) {
        await axiosClient.patch(`users/${user._id}`, {
          experience: newExperience,
        });
        setExperience(newExperience);
        setTempExperience(newExperience);
        setEditingExperienceIndex(null);
        toast("Experience removed successfully", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    } catch (error) {
      console.error("Error removing experience:", error);
      toast("Failed to remove experience", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const addExperienceDescription = (expIndex: number) => {
    const newExperience = [...experience];
    newExperience[expIndex].description.push({ id: uuidv4(), text: "" });
    setExperience(newExperience);
  };

  const removeExperienceDescription = (expIndex: number, descId: string) => {
    const newExperience = [...experience];
    newExperience[expIndex].description = newExperience[
      expIndex
    ].description.filter((desc) => desc.id !== descId); // Remove by ID
    setExperience(newExperience);
  };

  const addProject = () => {
    const newProject = {
      title: "",
      projectName: "",
      duration: "",
      description: [{ id: uuidv4(), text: "" }],
    };
    setTempProjects([...projects, newProject]);
    setProjects([...projects, newProject]);
    setEditingProjectIndex(projects.length); // Immediately set editing index to the new item
  };

  const removeProject = async (index: number) => {
    try {
      const newProjects = projects.filter((_, i) => i !== index);
      const user = JSON.parse(String(localStorage.getItem("user")));
      if (user) {
        await axiosClient.patch(`users/${user._id}`, { projects: newProjects });
        setProjects(newProjects);
        setTempProjects(newProjects);
        setEditingProjectIndex(null);
        toast("Project removed successfully", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    } catch (error) {
      console.error("Error removing project:", error);
      toast("Failed to remove project", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const addProjectDescription = (projectIndex: number) => {
    const newProjects = [...projects];
    newProjects[projectIndex].description.push({ id: uuidv4(), text: "" });
    setProjects(newProjects);
  };

  const removeProjectDescription = (projectIndex: number, descId: string) => {
    const newProjects = [...projects];
    newProjects[projectIndex].description = newProjects[
      projectIndex
    ].description.filter((desc) => desc.id !== descId); // Remove by ID
    setProjects(newProjects);
  };

  const addSkill = () => {
    setSkills([...skills, ""]);
  };

  const removeSkill = (index: number) => {
    setSkills(skills.filter((_, i) => i !== index));
  };

  const addInterest = () => {
    setInterests([...interests, ""]);
  };

  const removeInterest = (index: number) => {
    setInterests(interests.filter((_, i) => i !== index));
  };

  const handleEditClick = () => {
    setTempFirstName(firstName);
    setTempLastName(lastName);
    setTempExperienceLevel(experienceLevel);
    setTempLocation(location);
    setTempLinkedin(linkedin);
    setTempWebsite(website);
    setIsDialogOpen(true);
  };

  const handleSaveProfile = async () => {
    try {
      const user = JSON.parse(String(localStorage.getItem("user")));
      if (user) {
        // Format URLs before saving
        const formattedLinkedin = formatUrl(tempLinkedin, 'linkedin');
        const formattedWebsite = formatUrl(tempWebsite, 'website');

        await axiosClient.patch(`users/${user._id}`, {
          firstName: tempFirstName,
          lastName: tempLastName,
          linkedin: formattedLinkedin,
          website: formattedWebsite,
          location: tempLocation,
          experienceLevel: tempExperienceLevel,
        });

        setFirstName(tempFirstName);
        setLastName(tempLastName);
        setLinkedin(formattedLinkedin);
        setWebsite(formattedWebsite);
        setLocation(tempLocation);
        setExperienceLevel(tempExperienceLevel);
        setIsDialogOpen(false);
        toast("Profile updated Successfully", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    } catch (error: any) {
      toast(error.response?.data?.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const handleCancelEdit = (section: string) => {
    switch (section) {
      case "education":
        // If it's a newly added education (last item) and being edited, remove it
        if (editingEducationIndex === education.length - 1 && tempEducation[editingEducationIndex].degree === "") {
          const newEducation = education.slice(0, -1);
          setEducation(newEducation);
          setTempEducation(newEducation);
        } else {
          setTempEducation([...education]);
        }
        setEditingEducationIndex(null);
        break;

      case "experience":
        // If it's a newly added experience (last item) and being edited, remove it
        if (editingExperienceIndex === experience.length - 1 && tempExperience[editingExperienceIndex].title === "") {
          const newExperience = experience.slice(0, -1);
          setExperience(newExperience);
          setTempExperience(newExperience);
        } else {
          setTempExperience([...experience]);
        }
        setEditingExperienceIndex(null);
        break;

      case "projects":
        // If it's a newly added project (last item) and being edited, remove it
        if (editingProjectIndex === projects.length - 1 && tempProjects[editingProjectIndex].projectName === "") {
          const newProjects = projects.slice(0, -1);
          setProjects(newProjects);
          setTempProjects(newProjects);
        } else {
          setTempProjects([...projects]);
        }
        setEditingProjectIndex(null);
        break;

      default:
        break;
    }
  };

  const handleSaveExperience = async (index: number) => {
    try {
      const user = JSON.parse(String(localStorage.getItem("user")));
      if (user) {
        await axiosClient.patch(`users/${user._id}`, { experience });
        setExperience(tempExperience);
        setEditingExperienceIndex(null);
        setSuccess("Experience updated successfully");
        setTimeout(() => setSuccess(""), 10000);
      }
    } catch (error) {
      console.error("Error updating experience:", error);
      setError("Failed to update experience.");
      setTimeout(() => setError(""), 10000);
    }
  };

  const toggleEdit = (section: string, index: number) => {
    switch (section) {
      case "education":
        if (editingEducationIndex === index) {
          handleCancelEdit(section); // Canceling edit mode
        } else {
          const originalEducation = [...education];
          setEditingEducationIndex(index);
          setTempEducation([...originalEducation]);
        }
        break;
      case "experience":
        if (editingExperienceIndex === index) {
          handleCancelEdit(section); // Canceling edit mode
        } else {
          const originalExperience = [...experience];
          setEditingExperienceIndex(index);
          setTempExperience([...originalExperience]);
        }
        break;
      case "projects":
        if (editingProjectIndex === index) {
          handleCancelEdit(section); // Canceling edit mode
        } else {
          const originalProjects = [...projects];
          setEditingProjectIndex(index);
          setTempProjects([...originalProjects]);
        }
        break;
      default:
        break;
    }
  };

  const handleSaveEducation = async (index: number) => {
    try {
      const user = JSON.parse(String(localStorage.getItem("user")));
      if (user) {
        await axiosClient.patch(`users/${user._id}`, { education });
        setEducation(tempEducation);
        setEditingEducationIndex(null);
        setSuccess("Education updated successfully");
        setTimeout(() => setSuccess(""), 10000);
      }
    } catch (error) {
      console.error("Error updating education:", error);
      setError("Failed to update education.");
      setTimeout(() => setError(""), 10000);
    }
  };

  const handleSaveProjects = async (index: number) => {
    try {
      const user = JSON.parse(String(localStorage.getItem("user")));
      if (user) {
        await axiosClient.patch(`users/${user._id}`, { projects });
        setProjects(tempProjects);
        setEditingProjectIndex(null);
        setSuccess("Projects updated successfully");
        setTimeout(() => setSuccess(""), 10000);
      }
    } catch (error) {
      console.error("Error updating projects:", error);
      setError("Failed to update projects.");
      setTimeout(() => setError(""), 10000);
    }
  };

  const handleSave = (section: string, index: number) => {
    if (section === "experience") {
      handleSaveExperience(index);
    } else if (section === "education") {
      handleSaveEducation(index);
    } else if (section === "projects") {
      handleSaveProjects(index);
    }
  };

  const handleShare = () => {
    if (username) {
      navigate(`/portfolio/${username}`);
    } else {
      toast("Username not found", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const handleDragStart = (
    e: DragEvent<HTMLDivElement>,
    section: string,
    itemIndex: number,
    descIndex: number
  ) => {
    e.dataTransfer.setData(
      "text/plain",
      JSON.stringify({
        section,
        itemIndex,
        descIndex,
      })
    );
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (
    e: DragEvent<HTMLDivElement>,
    section: string,
    itemIndex: number,
    descIndex: number
  ) => {
    e.preventDefault();
    const data = JSON.parse(e.dataTransfer.getData("text/plain"));

    if (data.section !== section || data.itemIndex !== itemIndex) return;

    if (section === "experience") {
      const newExperience = [...tempExperience];
      const descriptions = newExperience[itemIndex].description;
      const [movedDesc] = descriptions.splice(data.descIndex, 1);
      descriptions.splice(descIndex, 0, movedDesc);
      setTempExperience(newExperience);
    } else if (section === "projects") {
      const newProjects = [...tempProjects];
      const descriptions = newProjects[itemIndex].description;
      const [movedDesc] = descriptions.splice(data.descIndex, 1);
      descriptions.splice(descIndex, 0, movedDesc);
      setTempProjects(newProjects);
    }
  };

  const generatePastelColor = (name: string) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const h = hash % 360;
    return `hsla(${h}, 70%, 80%, 0.2)`;
  };

  const getInitials = () => {
    const firstInitial = firstName ? firstName[0] : '';
    const lastInitial = lastName ? lastName[0] : '';
    return (firstInitial + lastInitial).toUpperCase();
  };

  const handleLinkedinPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    setTempLinkedin(formatUrl(pastedText, 'linkedin'));
  };

  const handleWebsitePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    setTempWebsite(formatUrl(pastedText, 'website'));
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedNumber = formatPhoneNumber(e.target.value);
    setPhone(formattedNumber);
  };

  const handlePhonePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    setPhone(formatPhoneNumber(pastedText));
  };

  return (
    <div className="account-info-container">
      <Sidebar />
      <div className="account-info">
        <div className="profile-header">
          <div className="left-section">
            <div className="profile-info">
              <div
                className="profile-picture"
                style={!profileImage ? {
                  backgroundColor: generatePastelColor(firstName + lastName),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '120px',
                  height: '120px',
                } : undefined}
              >
                {profileImage ? (
                  <img src={profileImage} alt="Profile" />
                ) : (
                  <span className="initials">{getInitials()}</span>
                )}
              </div>
              <h1>
                {firstName} {lastName}
              </h1>
            </div>

            <div className="profile-details">
              <div className="skills-section">
                {skills.slice(0, 3).map((skill, index) => (
                  <span key={index} className="skill-badge">
                    {skill}
                  </span>
                ))}
              </div>
              <div className="experience-location">
                <div className="info-item">
                  <span className="info-value">{experienceLevel}</span>
                  <span className="info-label">Exp. Level</span>
                </div>
                <div className="divider"></div>
                <div className="info-item">
                  <span className="info-value">{location}</span>
                  <span className="info-label">Located</span>
                </div>
              </div>
            </div>
          </div>

          <div className="right-section">
            <div className="social-icons">
              <a
                href={`mailto:${email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaEnvelope />
              </a>
              <a href={linkedin} target="_blank" rel="noopener noreferrer">
                <FaLinkedin />
              </a>
              <a
                href={`https://github.com/${email.split("@")[0]}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaGithub />
              </a>
              <a
                href={`https://twitter.com/${email.split("@")[0]}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaXTwitter />
              </a>
            </div>
            <div className="share-button" onClick={handleShare}>
              <FiShare2 />
              Share Portfolio
            </div>
            <div className="resume-button">Resume</div>
            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
              <DialogContent className="dialog-content">
                <DialogHeader className="dialog-header">
                  <DialogTitle className="dialog-title">
                    Edit Profile
                  </DialogTitle>
                  <DialogDescription className="dialog-description">
                    Update your profile details below.
                  </DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                  <div className="dialog-input-field">
                    <Label htmlFor="firstName" className="text-left text-black">
                      First Name
                    </Label>
                    <Input
                      id="firstName"
                      value={tempFirstName}
                      onChange={(e) => setTempFirstName(e.target.value)}
                    />
                  </div>
                  <div className="dialog-input-field">
                    <Label htmlFor="lastName" className="text-left text-black">
                      Last Name
                    </Label>
                    <Input
                      id="lastName"
                      value={tempLastName}
                      onChange={(e) => setTempLastName(e.target.value)}
                    />
                  </div>
                  <div className="dialog-input-field">
                    <Label
                      htmlFor="experienceLevel"
                      className="text-left text-black"
                    >
                      Experience Level
                    </Label>
                    {/* <select id="experienceLevel" value={tempExperienceLevel} onChange={(e) => setTempExperienceLevel(e.target.value)} className="border rounded-md p-2">
                      <option value="Entry Level">Entry Level</option>
                      <option value="Mid Career">Mid Career</option>
                      <option value="Experienced">Experienced</option>
                    </select> */}
                    <Select
                      value={tempExperienceLevel}
                      onValueChange={(e) => setTempExperienceLevel(e)}
                    >
                      <SelectTrigger className="w-[65%] border border-input">
                        <SelectValue placeholder="Select you Experience Level"></SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectLabel>Experience Level</SelectLabel>
                          <SelectItem value="Entry Level">
                            Entry Level
                          </SelectItem>
                          <SelectItem value="Mid Career">Mid Career</SelectItem>
                          <SelectItem value="Experienced">
                            Experienced
                          </SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="dialog-input-field">
                    <Label htmlFor="location" className="text-left text-black">
                      Location
                    </Label>
                    <Input
                      id="location"
                      value={tempLocation}
                      onChange={(e) => setTempLocation(e.target.value)}
                    />
                  </div>
                  <div className="dialog-input-field">
                    <Label htmlFor="linkedin" className="text-left text-black">
                      LinkedIn URL
                    </Label>
                    <Input
                      id="linkedin"
                      value={tempLinkedin}
                      onChange={(e) => setTempLinkedin(formatUrl(e.target.value, 'linkedin'))}
                      onPaste={handleLinkedinPaste}
                      placeholder="https://"
                    />
                  </div>
                  <div className="dialog-input-field">
                    <Label htmlFor="website" className="text-left text-black">
                      GitHub URL
                    </Label>
                    <Input
                      id="website"
                      value={tempWebsite}
                      onChange={(e) => setTempWebsite(formatUrl(e.target.value, 'website'))}
                      onPaste={handleWebsitePaste}
                      placeholder="https://"
                    />
                  </div>
                </div>
                <div className="dialog-actions flex justify-end gap-4">
                  <DialogClose asChild>
                    <button
                      type="button"
                      className="cancel-button"
                      onClick={() => handleCancelEdit("profile")}
                    >
                      Cancel
                    </button>
                  </DialogClose>
                  <button
                    type="button"
                    className="save-button"
                    onClick={handleSaveProfile}
                  >
                    Save
                  </button>
                </div>
              </DialogContent>
            </Dialog>
          </div>
          <button className="floating-edit-button" onClick={handleEditClick}>
            <AiOutlineEdit />
          </button>
        </div>

        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}

        <form onSubmit={handleUpdate}>
          <div className="input-field">
            <label>Phone</label>
            <input
              type="tel"
              value={phone}
              onChange={handlePhoneChange}
              onPaste={handlePhonePaste}
              placeholder="(123) 456-7890"
              maxLength={14}
            />
          </div>
          <div className="input-field">
            <label>Website</label>
            <input
              type="url"
              value={website}
              onChange={(e) => setWebsite(formatUrl(e.target.value, 'website'))}
              onPaste={handleWebsitePaste}
              placeholder="https://"
            />
          </div>
          <div className="input-field">
            <label>Citizenship</label>
            <input
              type="text"
              value={citizenship}
              onChange={(e) => setCitizenship(e.target.value)}
            />
          </div>

          <div className="section">
            <h3 className="section-title">Education</h3>
            {tempEducation.map((edu, index) => (
              <div
                key={index}
                className={`education-item ${editingEducationIndex === index ? "editing" : ""}`}
                onClick={() => toggleEdit("education", index)}
              >
                {editingEducationIndex === index ? (
                  <div className="input-field" onClick={(e) => e.stopPropagation()}>
                    <label>Degree</label>
                    <input
                      type="text"
                      value={edu.degree}
                      onChange={(e) => {
                        const newEducation = [...tempEducation];
                        newEducation[index].degree = e.target.value;
                        setTempEducation(newEducation);
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <label>Field of Study</label>
                    <input
                      type="text"
                      value={edu.field}
                      onChange={(e) => {
                        const newEducation = [...tempEducation];
                        newEducation[index].field = e.target.value;
                        setTempEducation(newEducation);
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <label>University</label>
                    <input
                      type="text"
                      value={edu.university}
                      onChange={(e) => {
                        const newEducation = [...tempEducation];
                        newEducation[index].university = e.target.value;
                        setTempEducation(newEducation);
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <label>Graduation Date</label>

                    <DatePicker
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <button
                            onClick={decreaseMonth}
                            disabled={prevMonthButtonDisabled}
                          >
                            {"<"}
                          </button>
                          <select
                            value={getYear(date)}
                            onChange={({ target: { value } }) =>
                              changeYear(Number(value))
                            }
                          >
                            {years.map((option: any) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <select
                            value={months[getMonth(date)]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <button
                            onClick={increaseMonth}
                            disabled={nextMonthButtonDisabled}
                          >
                            {">"}
                          </button>
                        </div>
                      )}
                      selected={
                        edu.graduationDate ? new Date(edu.graduationDate) : null
                      }
                      onChange={(date) => {
                        console.log("date", moment(date).format("MMMM YYYY"));
                        const newEducation = [...tempEducation];
                        newEducation[index].graduationDate = date
                          ? moment(date).format("MMMM YYYY")
                          : "";
                        setTempEducation(newEducation);
                      }}
                      dateFormat="MMMM yyyy"
                      showMonthYearPicker
                    />

                    <label>GPA (Optional)</label>
                    <input
                      type="text"
                      value={edu.gpa}
                      onChange={(e) => {
                        const newEducation = [...tempEducation];
                        newEducation[index].gpa = e.target.value;
                        setTempEducation(newEducation);
                      }}
                      className="gpa-input"
                      onClick={(e) => e.stopPropagation()}
                    />
                    <div className="edit-buttons">
                      <button
                        type="button"
                        className="save-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSave("education", index);
                        }}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="cancel-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCancelEdit("education");
                        }}
                      >
                        Cancel
                      </button>
                      <Dialog>
                        <DialogTrigger onClick={(e) => e.stopPropagation()}>Remove</DialogTrigger>
                        <DialogContent onClick={(e) => e.stopPropagation()}>
                          <DialogHeader>
                            <DialogTitle>Are you absolutely sure?</DialogTitle>
                            <DialogDescription>
                              This action cannot be undone. Are you sure you
                              want to permanently delete this experience?
                            </DialogDescription>
                          </DialogHeader>
                          <DialogFooter>
                            <Button
                              type="submit"
                              onClick={() => removeProject(index)}
                            >
                              Confirm
                            </Button>
                          </DialogFooter>
                        </DialogContent>
                      </Dialog>
                    </div>
                  </div>
                ) : (
                  <div className="info-content">
                    <div
                      className="top-row"
                      onClick={() => toggleEdit("education", index)}
                    >
                      <div className="item-title">
                        {edu.degree ? `${edu.degree}, ${edu.field}` : edu.field}
                      </div>
                      <div className="item-duration">{edu.graduationDate}</div>
                    </div>
                    <div className="item-subtitle">
                      {edu.university}
                      {edu.gpa && (
                        <span className="gpa"> | GPA: {edu.gpa}</span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
            <button type="button" className="section-add-button" onClick={addEducation}>Add Education</button>
          </div>

          <div className="section">
            <h3 className="section-title">Experience</h3>
            {tempExperience.map((exp, index) => (
              <div
                key={index}
                className={`experience-item ${
                  editingExperienceIndex === index ? "editing" : ""
                }`}
              >
                {editingExperienceIndex === index ? (
                  <div className="input-field">
                    <label>Title</label>
                    <input
                      type="text"
                      value={exp.title}
                      onChange={(e) => {
                        const newExperience = [...tempExperience];
                        newExperience[index].title = e.target.value;
                        setTempExperience(newExperience);
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <label>Company</label>
                    <input
                      type="text"
                      value={exp.company}
                      onChange={(e) => {
                        const newExperience = [...tempExperience];
                        newExperience[index].company = e.target.value;
                        setTempExperience(newExperience);
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <label>Location</label>
                    <input
                      type="text"
                      value={exp.location}
                      onChange={(e) => {
                        const newExperience = [...tempExperience];
                        newExperience[index].location = e.target.value;
                        setTempExperience(newExperience);
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <label>Duration</label>
                    <div className="date-range-container">
                      <DatePicker
                        selected={
                          exp.startDate ? new Date(exp.startDate) : null
                        }
                        onChange={(date) => {
                          const newExperience = [...tempExperience];
                          newExperience[index].startDate = date
                            ? moment(date).format("MMMM YYYY")
                            : "";
                          setTempExperience(newExperience);
                        }}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        placeholderText="Start Date"
                      />

                      <div className="current-position-checkbox">
                        <input
                          type="checkbox"
                          checked={exp.isCurrentPosition}
                          onChange={(e) => {
                            const newExperience = [...tempExperience];
                            newExperience[index].isCurrentPosition =
                              e.target.checked;
                            if (e.target.checked) {
                              newExperience[index].endDate = null;
                            }
                            setTempExperience(newExperience);
                          }}
                          id={`current-position-${index}`}
                        />
                        <label htmlFor={`current-position-${index}`}>
                          Current Position
                        </label>
                      </div>

                      {!exp.isCurrentPosition && (
                        <DatePicker
                          selected={exp.endDate ? new Date(exp.endDate) : null}
                          onChange={(date) => {
                            const newExperience = [...tempExperience];
                            newExperience[index].endDate = date
                              ? moment(date).format("MMMM YYYY")
                              : "";
                            setTempExperience(newExperience);
                          }}
                          dateFormat="MM/yyyy"
                          showMonthYearPicker
                          placeholderText="End Date"
                          minDate={
                            exp.startDate ? new Date(exp.startDate) : undefined
                          }
                        />
                      )}
                    </div>
                    <label>Description</label>
                    {exp.description.map((desc, descIndex) => (
                      <div
                        key={desc.id}
                        className="description-item"
                        draggable
                        onDragStart={(e) =>
                          handleDragStart(e, "experience", index, descIndex)
                        }
                        onDragOver={handleDragOver}
                        onDrop={(e) =>
                          handleDrop(e, "experience", index, descIndex)
                        }
                      >
                        <MdDragIndicator className="drag-icon" />
                        <textarea
                          value={desc.text}
                          onChange={(e) => {
                            const newExperience = [...tempExperience];
                            newExperience[index].description[descIndex].text =
                              e.target.value;
                            setTempExperience(newExperience);
                          }}
                          rows={2}
                          className="description-input"
                          onClick={(e) => e.stopPropagation()}
                        />
                        <AiOutlineDelete
                          className="delete-icon"
                          onClick={() =>
                            removeExperienceDescription(index, desc.id)
                          }
                        />
                      </div>
                    ))}
                    <div className="edit-buttons">
                      <button
                        type="button"
                        className="save-button"
                        onClick={() => handleSave("experience", index)}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="cancel-button"
                        onClick={() => handleCancelEdit("experience")}
                      >
                        Cancel
                      </button>
                      {/* <button
                        type="button"
                        className="remove-section-button"
                        onClick={() => removeExperience(index)}
                      >
                        Remove
                      </button> */}
                      <Dialog>
                        <DialogTrigger>Remove</DialogTrigger>
                        <DialogContent>
                          <DialogHeader>
                            <DialogTitle>Are you absolutely sure?</DialogTitle>
                            <DialogDescription>
                              This action cannot be undone. Are you sure you
                              want to permanently delete this Project?
                            </DialogDescription>
                          </DialogHeader>
                          <DialogFooter>
                            <Button
                              type="submit"
                              onClick={() => removeProject(index)}
                            >
                              Confirm
                            </Button>
                          </DialogFooter>
                        </DialogContent>
                      </Dialog>
                    </div>
                  </div>
                ) : (
                  <div
                    className="info-content"
                    onClick={() => toggleEdit("experience", index)}
                  >
                    <div className="top-row">
                      <div className="item-title">{exp.title}</div>
                      <div className="item-duration">
                        {exp.startDate} -{" "}
                        {exp.isCurrentPosition ? "Present" : exp.endDate}
                      </div>
                    </div>
                    <div className="bottom-row">
                      <div className="item-company">{exp.company}</div>
                      <div className="item-location">{exp.location}</div>
                    </div>
                    <ul className="item-subtitle">
                      {exp.description.map((desc, descIndex) => (
                        <li key={descIndex}>{desc.text}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}
            <button type="button" className="section-add-button" onClick={addExperience}>Add Experience</button>
          </div>

          <div className="section">
            <h3 className="section-title">Projects</h3>
            {tempProjects.map((project, index) => (
              <div
                key={index}
                className={`project-item ${
                  editingProjectIndex === index ? "editing" : ""
                }`}
              >
                {editingProjectIndex === index ? (
                  <div className="input-field">
                    <label>Title (Optional)</label>
                    <input
                      type="text"
                      value={project.title}
                      onChange={(e) => {
                        const newProjects = [...tempProjects];
                        newProjects[index].title = e.target.value;
                        setTempProjects(newProjects);
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <label>Project Name</label>
                    <input
                      type="text"
                      value={project.projectName}
                      onChange={(e) => {
                        const newProjects = [...tempProjects];
                        newProjects[index].projectName = e.target.value;
                        setTempProjects(newProjects);
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <label>Duration (Optional)</label>
                    <input
                      type="text"
                      value={project.duration}
                      onChange={(e) => {
                        const newProjects = [...tempProjects];
                        newProjects[index].duration = e.target.value;
                        setTempProjects(newProjects);
                      }}
                      className="duration-input"
                      onClick={(e) => e.stopPropagation()}
                    />
                    <label>Description</label>
                    {project.description.map((desc, descIndex) => (
                      <div
                        key={desc.id}
                        className="description-item"
                        draggable
                        onDragStart={(e) =>
                          handleDragStart(e, "projects", index, descIndex)
                        }
                        onDragOver={handleDragOver}
                        onDrop={(e) =>
                          handleDrop(e, "projects", index, descIndex)
                        }
                      >
                        <MdDragIndicator className="drag-icon" />
                        <textarea
                          value={desc.text}
                          onChange={(e) => {
                            const newProjects = [...tempProjects];
                            newProjects[index].description[descIndex].text =
                              e.target.value;
                            setTempProjects(newProjects);
                          }}
                          rows={2}
                          className="description-input"
                          onClick={(e) => e.stopPropagation()}
                        />
                        <AiOutlineDelete
                          className="delete-icon"
                          onClick={() =>
                            removeProjectDescription(index, desc.id)
                          }
                        />
                      </div>
                    ))}
                    <div className="edit-buttons">
                      <button
                        type="button"
                        className="save-button"
                        onClick={() => handleSave("projects", index)}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="cancel-button"
                        onClick={() => handleCancelEdit("projects")}
                      >
                        Cancel
                      </button>
                      {/* <button
                        type="button"
                        className="remove-section-button"
                        onClick={() => removeProject(index)}
                      >
                        Remove
                      </button> */}
                      <Dialog>
                        <DialogTrigger>Remove</DialogTrigger>
                        <DialogContent>
                          <DialogHeader>
                            <DialogTitle>Are you absolutely sure?</DialogTitle>
                            <DialogDescription>
                              This action cannot be undone. Are you sure you
                              want to permanently delete this file from our
                              servers?
                            </DialogDescription>
                          </DialogHeader>
                          <DialogFooter>
                            <Button
                              type="submit"
                              onClick={() => removeProject(index)}
                            >
                              Confirm
                            </Button>
                          </DialogFooter>
                        </DialogContent>
                      </Dialog>
                    </div>
                  </div>
                ) : (
                  <div
                    className="info-content"
                    onClick={() => toggleEdit("projects", index)}
                  >
                    <div className="top-row">
                      <div className="item-title">
                        {project.title
                          ? `${project.projectName} (${project.title})`
                          : project.projectName}
                      </div>
                      {project.duration && (
                        <div className="item-duration">{project.duration}</div>
                      )}
                    </div>
                    <ul className="item-subtitle">
                      {project.description.map((desc, descIndex) => (
                        <li key={descIndex}>{desc.text}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}
            <button type="button" className="section-add-button" onClick={addProject}>Add Project</button>
          </div>

          <div className="section">
            <h3 className="section-title">Skills</h3>
            <div className="skills-container">
              {skills.map((skill, index) => (
                <span key={index} className="skill-badge">
                  <input
                    type="text"
                    value={skill}
                    onChange={(e) => {
                      const newSkills = [...skills];
                      newSkills[index] = e.target.value;
                      setSkills(newSkills);
                      e.target.style.width = `${e.target.value.length + 1}ch`; // Adjust width dynamically
                    }}
                    className="skill-input"
                    onClick={(e) => e.stopPropagation()}
                    style={{ width: `${skill.length + 1}ch` }} // Initial width based on content
                  />
                  <button
                    type="button"
                    className="delete-button"
                    onClick={() => removeSkill(index)}
                  >
                    ×
                  </button>
                </span>
              ))}
              <button type="button" className="add-button" onClick={addSkill}>
                Add Skill
              </button>
            </div>
          </div>

          <div className="section">
            <h3 className="section-title">Interests</h3>
            <div className="interests-container">
              {interests.map((interest, index) => (
                <span key={index} className="skill-badge">
                  <input
                    type="text"
                    value={interest}
                    onChange={(e) => {
                      const newInterests = [...interests];
                      newInterests[index] = e.target.value;
                      setInterests(newInterests);
                      e.target.style.width = `${e.target.value.length + 1}ch`; // Adjust width dynamically
                    }}
                    className="skill-input"
                    onClick={(e) => e.stopPropagation()}
                    style={{ width: `${interest.length + 1}ch` }} // Initial width based on content
                  />
                  <button
                    type="button"
                    className="delete-button"
                    onClick={() => removeInterest(index)}
                  >
                    ×
                  </button>
                </span>
              ))}
              <button
                type="button"
                className="add-button"
                onClick={addInterest}
              >
                Add Interest
              </button>
            </div>
          </div>

          <button
            type="submit"
            className="update-button"
            onClick={handleSaveProfile}
          >
            Update
          </button>
        </form>
      </div>
    </div>
  );
};

export default AccountInfo;