import React, { useState, useEffect, useRef } from "react";
import '../styles/components/resume.scss';
import {
  ResumeText,
  BulletPointSuggestion,
  Education,
  Experience,
  Project,
} from "./ResumeComponent";
import aiIcon from '../assets/ai-icon.svg';

interface ResumeProps {
  revisedResume: ResumeText;
  isEditingLocked: boolean;
  handleFieldChange: (updater: (prev: ResumeText) => ResumeText) => void;
  handleTailorBulletPoint: (
    expIndex: number,
    descId: string
  ) => Promise<void>;
  selectedBulletPoint: BulletPointSuggestion | null;
  handleAcceptSuggestion: (expIndex: number, descId: string) => void;
  handleRejectSuggestion: () => void;
  expandedBulletPoint: { expIndex: number, descId: string } | null;
}

const Resume: React.FC<ResumeProps> = ({
  revisedResume,
  isEditingLocked,
  handleFieldChange,
  handleTailorBulletPoint,
  selectedBulletPoint,
  handleAcceptSuggestion,
  handleRejectSuggestion,
  expandedBulletPoint,
}) => {
  const [selectedPoint, setSelectedPoint] = useState<{
    expIndex: number;
    descId: string;
  } | null>(null);
  const [showAIButton, setShowAIButton] = useState(false);
  const [aiButtonPosition, setAiButtonPosition] = useState({ top: 0, left: 0 });
  const bulletPointRef = useRef<HTMLDivElement>(null);
  const [suggestionWidth, setSuggestionWidth] = useState<number>(0);
  const [aiButtonWidth, setAiButtonWidth] = useState<number>(0);

  const handleBulletPointClick = (expIndex: number, descId: string) => {
    if (bulletPointRef.current) {
      const rect = bulletPointRef.current.getBoundingClientRect();
      const buttonWidth = 160; // Width of the tailor-ai-btn
      const centerPosition = rect.left + (rect.width / 2) - (buttonWidth / 2);
      
      setAiButtonPosition({
        top: rect.bottom + 5,
        left: centerPosition,
      });
    }
    setSelectedPoint({ expIndex, descId });
    setShowAIButton(true);
  };

  const handleSelectionChange = () => {
    const selection = window.getSelection();
    const selectedText = selection?.toString().trim();
    const range = selection?.getRangeAt(0);

    if (range && selectedText) {
      const bulletPointElement = range.startContainer.parentElement?.closest('.bullet-points li');
      
      if (bulletPointElement) {
        const rect = range.getBoundingClientRect();
        const buttonWidth = 160; // Width of the tailor-ai-btn
        
        // Adjust this calculation to push the button more towards the right
        const centerPosition = rect.left + (rect.width * 0.7) - (buttonWidth / 2);  // Changed from 0.5 to 0.7
        
        setAiButtonPosition({
          top: rect.bottom + 5,
          left: centerPosition,
        });
        setShowAIButton(true);
      } else {
        setShowAIButton(false);
      }
    } else {
      setShowAIButton(false);
    }
  };

  useEffect(() => {
    document.addEventListener("selectionchange", handleSelectionChange);
    return () => {
      document.removeEventListener("selectionchange", handleSelectionChange);
    };
  }, []);

  // Add a resize observer to track width changes
  useEffect(() => {
    if (bulletPointRef.current) {
      setSuggestionWidth(bulletPointRef.current.offsetWidth);
      setAiButtonWidth(bulletPointRef.current.offsetWidth);

      const resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
          if (entry.target instanceof HTMLElement) {
            setSuggestionWidth(entry.target.offsetWidth);
            setAiButtonWidth(entry.target.offsetWidth);
          }
        }
      });

      resizeObserver.observe(bulletPointRef.current);
      return () => resizeObserver.disconnect();
    }
  }, [selectedPoint]);

  // Also add a window resize listener for good measure
  useEffect(() => {
    const handleResize = () => {
      if (bulletPointRef.current) {
        setSuggestionWidth(bulletPointRef.current.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={`resume-rectangle ${isEditingLocked ? "locked" : ""}`}>
      <div className="resume-header">
        <div
          contentEditable={!isEditingLocked}
          suppressContentEditableWarning
          onBlur={(e) =>
            handleFieldChange((prev) => ({
              ...prev,
              name: e.currentTarget.innerText ? e.currentTarget.innerText : "",
            }))
          }
          className="resume-name"
        >
          {revisedResume.firstName} {revisedResume.lastName}
        </div>
        <p className="contact-info">
          {revisedResume.email && (
            <>
              <div
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => ({
                    ...prev,
                    email: e.currentTarget.innerText ? e.currentTarget.innerText : "",
                  }))
                }
              >
                {revisedResume.email}
              </div>
              {(revisedResume.phone || revisedResume.linkedin || revisedResume.website || revisedResume.location || revisedResume.citizenship) && (
                <span className="divider">|</span>
              )}
            </>
          )}

          {revisedResume.phone && (
            <>
              <div
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => ({
                    ...prev,
                    phone: e.currentTarget.innerText ? e.currentTarget.innerText : "",
                  }))
                }
              >
                {revisedResume.phone}
              </div>
              {(revisedResume.linkedin || revisedResume.website || revisedResume.location || revisedResume.citizenship) && (
                <span className="divider">|</span>
              )}
            </>
          )}

          {revisedResume.linkedin && (
            <>
              <div
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => ({
                    ...prev,
                    linkedin: e.currentTarget.innerText ? e.currentTarget.innerText : "",
                  }))
                }
              >
                {revisedResume.linkedin}
              </div>
              {(revisedResume.website || revisedResume.location || revisedResume.citizenship) && (
                <span className="divider">|</span>
              )}
            </>
          )}

          {revisedResume.website && (
            <>
              <div
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => ({
                    ...prev,
                    website: e.currentTarget.innerText ? e.currentTarget.innerText : "",
                  }))
                }
              >
                {revisedResume.website}
              </div>
              {(revisedResume.location || revisedResume.citizenship) && (
                <span className="divider">|</span>
              )}
            </>
          )}

          {revisedResume.location && (
            <>
              <div
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => ({
                    ...prev,
                    location: e.currentTarget.innerText ? e.currentTarget.innerText : "",
                  }))
                }
              >
                {revisedResume.location}
              </div>
              {(revisedResume.citizenship) && (
                <span className="divider">|</span>
              )}
            </>
          )}

          {revisedResume.citizenship && (
            <div
              contentEditable={!isEditingLocked}
              suppressContentEditableWarning
              onBlur={(e) =>
                handleFieldChange((prev) => ({
                  ...prev,
                  citizenship: e.currentTarget.innerText ? e.currentTarget.innerText : "",
                }))
              }
            >
              {revisedResume.citizenship}
            </div>
          )}
        </p>
      </div>

      <div className="education-section">
        <h2>EDUCATION</h2>
        {revisedResume.education.map((edu: Education, index: number) => (
          <div key={index} className="education-item">
            <div className="education-header">
              <div
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => {
                    const updatedEducation = [...prev.education];
                    updatedEducation[index].university = e.currentTarget.innerText;
                    return { ...prev, education: updatedEducation };
                  })
                }
                className="education-title"
              >
                {edu.university}
              </div>
              <div
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => {
                    const updatedEducation = [...prev.education];
                    updatedEducation[index].gpa = e.currentTarget.innerText;
                    return { ...prev, education: updatedEducation };
                  })
                }
                className="gpa right-aligned"
              >
                {edu.gpa && `GPA: ${edu.gpa}`}
              </div>
            </div>
            <div className="education-subheader">
              <div className="degree-field">
                <span
                  contentEditable={!isEditingLocked}
                  suppressContentEditableWarning
                  onBlur={(e) =>
                    handleFieldChange((prev) => {
                      const updatedEducation = [...prev.education];
                      updatedEducation[index].degree = e.currentTarget.innerText;
                      return { ...prev, education: updatedEducation };
                    })
                  }
                >
                  {edu.degree}
                </span>
                {edu.field && (
                  <span
                    contentEditable={!isEditingLocked}
                    suppressContentEditableWarning
                    onBlur={(e) =>
                      handleFieldChange((prev) => {
                        const updatedEducation = [...prev.education];
                        updatedEducation[index].field = e.currentTarget.innerText;
                        return { ...prev, education: updatedEducation };
                      })
                    }
                  >
                    , {edu.field}
                  </span>
                )}
              </div>
              <div
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => {
                    const updatedEducation = [...prev.education];
                    updatedEducation[index].graduationDate = e.currentTarget.innerText;
                    return { ...prev, education: updatedEducation };
                  })
                }
                className="graduation-date right-aligned"
              >
                {edu.graduationDate}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="experience-section">
        <h2>EXPERIENCE</h2>
        {revisedResume.experience.map((exp: Experience, expIndex: number) => (
          <div key={expIndex} className="experience-item">
            <div className="experience-header">
              <div
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => {
                    const updatedExperience = [...prev.experience];
                    updatedExperience[expIndex].title = e.currentTarget.innerText
                      ? e.currentTarget.innerText
                      : "";
                    return { ...prev, experience: updatedExperience };
                  })
                }
                className="job-title"
              >
                {exp.title}
              </div>
              <div
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => {
                    const updatedExperience = [...prev.experience];
                    updatedExperience[expIndex].duration = e.currentTarget.innerText
                      ? e.currentTarget.innerText
                      : "";
                    return { ...prev, experience: updatedExperience };
                  })
                }
                className="duration right-aligned"
              >
                {exp.duration}
              </div>
            </div>
            <div className="experience-subheader">
              <div
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => {
                    const updatedExperience = [...prev.experience];
                    updatedExperience[expIndex].company = e.currentTarget.innerText
                      ? e.currentTarget.innerText
                      : "";
                    return { ...prev, experience: updatedExperience };
                  })
                }
                className="company-name"
              >
                {exp.company}
              </div>
              <div
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => {
                    const updatedExperience = [...prev.experience];
                    updatedExperience[expIndex].location = e.currentTarget.innerText
                      ? e.currentTarget.innerText
                      : "";
                    return { ...prev, experience: updatedExperience };
                  })
                }
                className="location right-aligned"
              >
                {exp.location}
              </div>
            </div>
            <ul className="bullet-points">
              {exp.description.map((desc: { id: string; text: string }, descIndex: number) => (
                <li
                  key={desc.id}
                  className={`
                    ${selectedPoint?.descId === desc.id ? "selected" : ""}
                    ${expandedBulletPoint?.descId === desc.id ? "highlighted" : ""}
                  `}
                  onClick={() => handleBulletPointClick(expIndex, desc.id)}
                >
                  <div
                    ref={bulletPointRef}
                    contentEditable={!isEditingLocked}
                    suppressContentEditableWarning
                    onBlur={(e) =>
                      handleFieldChange((prev) => {
                        const updatedExperience = [...prev.experience];
                        const currentDescription = updatedExperience[expIndex].description.find(
                          (d) => d.id === desc.id
                        );

                        if (currentDescription) {
                          currentDescription.text = e.currentTarget.innerText;
                        }
                        return { ...prev, experience: updatedExperience };
                      })
                    }
                  >
                    {desc.text}
                  </div>

                  {selectedPoint?.expIndex === expIndex &&
                    selectedPoint?.descId === desc.id &&
                    showAIButton && (
                      <div
                        className={`tailor-ai-btn-container visible`}
                        style={{
                          position: "absolute",
                          width: aiButtonWidth ? `${aiButtonWidth}px` : 'auto',
                          maxWidth: '100%',
                          zIndex: 1000,
                          left: '80%',           // Add this
                          transform: 'translateX(-50%)'  // Add this
                        }}
                      >
                        <button
                          className="tailor-ai-btn"
                          onClick={() => handleTailorBulletPoint(expIndex, desc.id)}
                        >
                          <img 
                            src={aiIcon}
                            alt="AI Icon" 
                            width={20} 
                            height={20}
                            style={{ filter: 'invert(58%) sepia(7%) saturate(98%) hue-rotate(314deg) brightness(89%) contrast(86%)' }}
                          />
                          <span className="btn-text">Tailor with AI</span>
                        </button>
                      </div>
                    )}

                  {selectedBulletPoint &&
                    selectedBulletPoint.expIndex === expIndex &&
                    selectedBulletPoint.descId === desc.id && (
                      <div 
                        className="bullet-point-suggestion" 
                        role="button"
                        style={{ 
                          width: suggestionWidth ? `${suggestionWidth}px` : 'auto',
                          maxWidth: '100%'
                        }}
                      >
                        <div className="suggestion-header">
                          <img 
                            src={aiIcon}
                            alt="AI Icon" 
                            width={20} 
                            height={20}
                            style={{ filter: 'invert(58%) sepia(7%) saturate(98%) hue-rotate(314deg) brightness(89%) contrast(86%)' }}
                          />
                          <span className="suggestion-title">
                            Suggestion
                          </span>
                        </div>
                        
                        {selectedBulletPoint.isLoading ? (
                          <div className="skeleton-lines">
                            <div className="skeleton-loader" style={{ width: '100%' }}></div>
                            <div className="skeleton-loader" style={{ width: '60%' }}></div>
                          </div>
                        ) : (
                          <>
                            {selectedBulletPoint.suggested ? (
                              <div>{selectedBulletPoint.suggested}</div>
                            ) : (
                              ""
                            )}
                            <button
                              className="bullet-point-button"
                              onClick={() => handleAcceptSuggestion(expIndex, desc.id)}
                            >
                              Approve
                            </button>
                            <button onClick={handleRejectSuggestion}>
                              Dismiss
                            </button>
                          </>
                        )}
                      </div>
                    )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div className="projects-section">
        <h2>PROJECTS</h2>
        {revisedResume.projects.map((project, index) => (
          <div key={index} className="project-item">
            <div className="project-header">
              <div className="project-title-container">
                <div
                  contentEditable={!isEditingLocked}
                  suppressContentEditableWarning
                  onBlur={(e) =>
                    handleFieldChange((prev) => {
                      const updatedProjects = [...prev.projects];
                      updatedProjects[index].title = e.currentTarget.innerText;
                      return { ...prev, projects: updatedProjects };
                    })
                  }
                  className="project-title"
                >
                  {project.title}
                </div>
                <div
                  contentEditable={!isEditingLocked}
                  suppressContentEditableWarning
                  onBlur={(e) =>
                    handleFieldChange((prev) => {
                      const updatedProjects = [...prev.projects];
                      updatedProjects[index].project_name = e.currentTarget.innerText;
                      return { ...prev, projects: updatedProjects };
                    })
                  }
                  className="project-name"
                >
                  {project.project_name}
                </div>
              </div>
              <div
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => {
                    const updatedProjects = [...prev.projects];
                    updatedProjects[index].duration = e.currentTarget.innerText;
                    return { ...prev, projects: updatedProjects };
                  })
                }
                className="project-duration right-aligned"
              >
                {project.duration}
              </div>
            </div>
            <ul className="bullet-points">
              {project.description.map((desc: { id: string; text: string }, descIndex: number) => (
                <li
                  key={desc.id}
                  className={`
                    ${selectedPoint?.descId === desc.id ? "selected" : ""}
                    ${expandedBulletPoint?.descId === desc.id ? "highlighted" : ""} 
                  `}
                  onClick={() => handleBulletPointClick(index, desc.id)}
                >
                  <div
                    contentEditable={!isEditingLocked}
                    suppressContentEditableWarning
                    onBlur={(e) =>
                      handleFieldChange((prev) => {
                        const updatedProjects = [...prev.projects];
                        const currentDescription = updatedProjects[index].description.find(
                          (d) => d.id === desc.id
                        );

                        if (currentDescription) {
                          currentDescription.text = e.currentTarget.innerText;
                        }
                        return { ...prev, projects: updatedProjects };
                      })
                    }
                    className="project-description"
                  >
                    {desc.text}
                  </div>

                    {selectedPoint?.expIndex === index && 
                    selectedPoint?.descId === desc.id &&
                    showAIButton && (
                      <div
                        className={'tailor-ai-btn-container visible'}
                        style={{
                          position: 'absolute',
                          width: aiButtonWidth ? `${aiButtonWidth}px` : 'auto',
                          maxWidth: '100%',
                          zIndex: 1000,
                          left: '50%',           // Add this
                          transform: 'translateX(-50%)'  // Add this
                        }}
                      >
                        <button
                          className="tailor-ai-btn"
                          onClick={() => handleTailorBulletPoint(index, desc.id)}
                        >
                          <img 
                            src={aiIcon}
                            alt="AI Icon" 
                            width={20} 
                            height={20}
                            style={{ filter: 'invert(58%) sepia(7%) saturate(98%) hue-rotate(314deg) brightness(89%) contrast(86%)' }}
                          />
                          <span className="btn-text">Tailor with AI</span>
                        </button>
                      </div>
                    )}

                    {selectedBulletPoint && 
                      selectedBulletPoint.expIndex === index && 
                      selectedBulletPoint.descId === desc.id && (
                        <div className="bullet=point-suggestion" role="button">
                          {selectedBulletPoint.isLoading ? (
                            <p>Loading Suggestion.......</p>
                          ): (
                            <>
                              <div style={{ display: 'flex' }}>
                                <span style={{ marginRight: 5 }}>
                                  {/* AI Icon SVG */}
                                </span>
                                Tailor with AI
                              </div>
                            </>
                          )}

{selectedBulletPoint &&
                    selectedBulletPoint.expIndex === index &&
                    selectedBulletPoint.descId === desc.id && (
                      <div className="bullet-point-suggestion" role="button">
                        {selectedBulletPoint.isLoading ? (
                          <p>Loading suggestion...</p>
                        ) : (
                          <>
                            <div style={{ display: "flex" }}>
                              <span style={{ marginRight: 5 }}>
                                {/* AI Icon SVG */}
                              </span>
                              <p className="bullet-point-suggestion-title">
                                Suggested bullet point:
                              </p>
                            </div>
                            {selectedBulletPoint.suggested ? (
                              <div>{selectedBulletPoint.suggested}</div>
                            ) : (
                              ""
                            )}
                            <button
                              className="bullet-point-button"
                              onClick={() => handleAcceptSuggestion(index, desc.id)}
                            >
                              Accept
                            </button>
                            <button onClick={handleRejectSuggestion}>
                              Dismiss
                            </button>
                          </>
                        )}
                      </div>
                    )}
                        </div>
                      )  
                    }
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div className="projects-section">  {/* Using same class as other sections */}
        <h2>{revisedResume.interests.length > 0 ? 'SKILLS & INTERESTS' : 'SKILLS'}</h2>
        <div className="skills-interests-content">
          <div className="skills-line">
            {revisedResume.interests.length > 0 && <span className="label bold-label">Skills: </span>}
            <span
              contentEditable={!isEditingLocked}
              suppressContentEditableWarning
              onBlur={(e) =>
                handleFieldChange((prev) => ({
                  ...prev,
                  skills: e.currentTarget.innerText
                    ? e.currentTarget.innerText
                        .split(",")
                        .map((skill) => skill.trim())
                    : [],
                }))
              }
              className="resume-skills"
            >
              {revisedResume.skills.join(", ")}
            </span>
          </div>
          {revisedResume.interests.length > 0 && (
            <div className="interests-line">
              <span className="label bold-label">Interests: </span>
              <span
                contentEditable={!isEditingLocked}
                suppressContentEditableWarning
                onBlur={(e) =>
                  handleFieldChange((prev) => ({
                    ...prev,
                    interests: e.currentTarget.innerText
                      ? e.currentTarget.innerText
                          .split(",")
                          .map((interest) => interest.trim())
                      : [],
                  }))
                }
                className="resume-interests"
              >
                {revisedResume.interests.join(", ")}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Resume;