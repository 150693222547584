import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiBriefcase, FiFileText, FiUser, FiLogOut, FiSearch, FiMenu, FiGrid, FiMessageSquare } from 'react-icons/fi';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import '../styles/components/sidebar.scss';
import { CiGrid41 } from "react-icons/ci";

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleLogout = async () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
    navigate('/'); // Redirect to the landing page
    window.location.reload()
  };

  const toggleSidebar = () => {
    if (isExpanded) {
      setIsHovered(false);
    }
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className={`sidebar ${isExpanded ? 'expanded' : ''} ${isHovered && !isExpanded ? 'hovered' : ''}`}
      onMouseEnter={() => !isExpanded && setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="sidebar-top">
        <div className="sidebar-toggle" onClick={toggleSidebar}>
          <FiMenu />
        </div>
        <div className="sidebar-icon" onClick={() => navigate('/dashboard')}>
          <FiGrid />
          <span className="icon-text">Dashboard</span>
        </div>
        <div className="sidebar-icon" onClick={() => navigate('/job-search')}>
          <FiBriefcase />
          <span className="icon-text">Job Search</span>
        </div>
        <div className="sidebar-icon" onClick={() => navigate('/chat')}>
          <FiMessageSquare />
          <span className="icon-text">QuickCruit AI</span>
        </div>
        {/* <div className="sidebar-icon" onClick={() => navigate('/resume-builder')}>
          <FiFileText />
          <span className="icon-text">Resume Builder</span>
        </div> */}
      </div>
      <div className="sidebar-bottom">
        <div className="sidebar-icon" onClick={() => navigate('/account-info')}>
          <FiUser />
          <span className="icon-text">Profile</span>
        </div>
        <div className="sidebar-icon" onClick={handleLogout}>
          <FiLogOut />
          <span className="icon-text">Logout</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
