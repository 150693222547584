import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ChatInput from './ChatInput';
import ChatMessage from './ChatMessage';
import Sidebar from '../Sidebar';
import '../../styles/components/chat.scss';
import { Plus } from 'lucide-react';
import axios from 'axios';
import type { AxiosInstance } from 'axios';

interface Message {
  id: string;
  content: string;
  role: 'user' | 'assistant';
}

const STORAGE_KEY = 'chat_messages';
const MAX_STORED_MESSAGES = 50;

const Chat: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>(() => {
    const stored = localStorage.getItem(STORAGE_KEY);
    return stored ? JSON.parse(stored) : [];
  });
  const [isTyping, setIsTyping] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [user, setUser] = useState<any>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const axiosClient = axios.create({
    baseURL: 'http://localhost:3000/v1',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    },
  });

  // Check authentication
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = localStorage.getItem('user');
        if (userData) {
          const user = JSON.parse(userData);
          if (user._id) {
            setUser(user);
          } else {
            navigate('/auth/login');
          }
        } else {
          navigate('/auth/login');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        navigate('/auth/login');
      }
    };

    fetchUserData();
  }, [navigate]);

  // Add effect to handle suggestions visibility
  useEffect(() => {
    setShowSuggestions(messages.length === 0);
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Save messages to localStorage whenever they change
  useEffect(() => {
    // Only store the last MAX_STORED_MESSAGES messages
    const messagesToStore = messages.slice(-MAX_STORED_MESSAGES);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(messagesToStore));
  }, [messages]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = async (content: string) => {
    if (isTyping) return;

    console.log('1. Starting to send message:', content);

    const userMessage = {
      id: Date.now().toString(),
      content,
      role: 'user' as const
    };

    console.log('2. Created user message:', userMessage);
    setMessages(prev => [...prev, userMessage]);
    setIsTyping(true);

    try {
      console.log('3. Sending request to:', '/chat/message');
      const response = await axiosClient.post('/chat/message', {
        message: content,
        chatHistory: messages.map(({ role, content }) => ({ role, content }))
      });
      console.log('4. Received response:', response.data);

      const assistantMessage = {
        id: Date.now().toString(),
        content: response.data.message,
        role: 'assistant' as const
      };

      setMessages(prev => [...prev, assistantMessage]);
    } catch (error) {
      console.error('Failed to get response:', error);
      if (axios.isAxiosError(error)) {
        console.error('Error details:', error.response?.data);
      }
    } finally {
      setIsTyping(false);
    }
  };

  const handleNewChat = () => {
    setMessages([]);
    setShowSuggestions(true);
  };

  return (
    <div className="app-container">
      <Sidebar />
      <div className="chat-page">
        <div className="chat-header">
          <div className="header-content">
            <button 
              className="new-chat-button"
              onClick={handleNewChat}
              title="New chat"
            >
              <Plus />
            </button>
          </div>
        </div>
        <div className="messages-container">
          {messages.map((message) => (
            <ChatMessage key={message.id} message={message} />
          ))}
          <div ref={messagesEndRef} />
        </div>
        <ChatInput 
          onSendMessage={handleSendMessage} 
          disabled={isTyping}
          showSuggestions={showSuggestions}
        />
      </div>
    </div>
  );
};

export default Chat;
