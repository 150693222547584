import React, { useState } from 'react';
import { FaChevronRight } from 'react-icons/fa6'; // Modern arrow icon
import '../styles/components/jobTile.scss';

interface JobTileProps {
  job: any; // Ideally, define a JobData interface
  userId: any;
  handleSelection: (data: any) => void;
}

const JobTile: React.FC<JobTileProps> = ({ job, userId, handleSelection }) => {
  const { _id, title, company, location, salary, jobType, days_to_live } = job;

  // Logic to dynamically generate the company logo path based on the naming convention
  const getCompanyLogo = (company: string | undefined): string[] => {
    if (!company) {
      return ['/company-logos/default-logo.png']; // Fallback image if the company is undefined
    }

    // Format the company name to match file naming conventions
    const formattedCompanyName = company.toLowerCase().replace(/\s/g, '-');

    // Define possible logo paths in priority order
    return [
      `/company-logos/${formattedCompanyName}_horizontal.png`, // Horizontal
      `/company-logos/${formattedCompanyName}_vertical.png`,   // Vertical
      `/company-logos/${formattedCompanyName}_square.png`,     // Square
      `/company-logos/default-logo.png`,                       // Fallback default logo
    ];
  };

  // Generate the company logo paths
  const logoPaths = getCompanyLogo(company);

  // State to track which logo path we are currently trying
  const [currentLogoIndex, setCurrentLogoIndex] = useState(0);

  // If the current logo fails to load, move to the next one
  const handleLogoError = () => {
    if (currentLogoIndex < logoPaths.length - 1) {
      setCurrentLogoIndex(currentLogoIndex + 1);
    }
  };

  // Determine if the current logo is horizontal
  const isHorizontal = logoPaths[currentLogoIndex].includes('_horizontal');

  // Function to map job types to corresponding class names and format correctly
  const getJobTypeClass = (jobType: string): string => {
    switch (jobType.toLowerCase()) {
      case 'full time':
        return 'full-time';
      case 'part time':
        return 'part-time';
      case 'internship':
        return 'internship';
      case 'contract':
        return 'contract';
      default:
        return '';
    }
  };

  // Correct job type text display
  const formatJobType = (jobType: string): string => {
    switch (jobType.toLowerCase()) {
      case 'full time':
        return 'FULL-TIME';
      case 'part time':
        return 'PART-TIME';
      default:
        return jobType.toUpperCase();
    }
  };

  // Get job type class name for dynamic styling
  const jobTypeClass = jobType ? getJobTypeClass(jobType) : '';

  // Determine status class
  const getStatusClass = (daysToLive: any): string => {
    console.log('Raw daysToLive:', daysToLive); // Keep this for debugging
    
    const days = daysToLive?.$numberInt 
      ? parseInt(daysToLive.$numberInt) 
      : typeof daysToLive === 'number' 
        ? daysToLive 
        : 0;
        
    console.log('Processed days:', days); // Keep this for debugging

    if (days >= 10) return 'recent';    // Green for newest (15-10 days left)
    if (days >= 5) return 'moderate';   // Yellow for moderate (9-5 days left)
    return 'old';                       // Red for oldest (4-0 days left)
  };

  return (
    <div className={`job-tile`}>
      <div className={`status-dot ${getStatusClass(days_to_live)}`} />

      {jobType && <div className={`job-type-tag ${jobTypeClass}`}>{formatJobType(jobType)}</div>}

      <div className="job-info">
        <div className={`company-logo-container ${isHorizontal ? 'horizontal-logo-container' : ''}`}>
          {/* Single img tag that attempts to load each path in order */}
          <img
            src={logoPaths[currentLogoIndex]}
            alt={company || 'Company'}
            className={`company-logo ${isHorizontal ? 'horizontal-logo' : ''}`}
            onError={handleLogoError}
          />
        </div>

        <h3 className="job-title" title={title}>
          {title}
        </h3>
        <p className="company-location">
          {company} • {location}
        </p>

        {/* Maintain spacing for salary placeholder */}
        <div className="salary-placeholder" />

        <div className="job-actions">
          <button onClick={() => handleSelection(job)} className="apply-button">
            Apply
          </button>
          {/* View Details button commented out as per original code */}
        </div>
      </div>
    </div>
  );
};

export default JobTile;
